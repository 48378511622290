import { CompleteLayout } from '../../components/forms/CompleteLayout';
import { HeadInner } from '../../components/Head';
import { Layout } from '../../components/layout';

export const Head = () => (
  <HeadInner
    path='/seminar/complete/'
    title='お申込みありがとうございます'
    noindex
  />
);

const CompletePage = () => {
  const links = [
    { name: 'お申込みありがとうございます。', path: '/seminar/complete/' },
  ];

  return (
    <Layout
      header='simple'
      footer='simple'
      background='simple'
      isBottomMenu={false}
      links={links}
    >
      <CompleteLayout isSeminar>
        <h2>
          お申込み
          <br />
          ありがとうございます。
        </h2>
        <div css={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
          <p>
            ご記入いただいたお問合せは送信完了しました。
            <br />
            確認のため、ご記入頂いたメールアドレスへ自動返信メールをお送りしております。
          </p>
          <p>
            しばらく経ってもメールが届かない場合は、
            <br />
            入力頂いたメールアドレスが間違っているか、迷惑メールフォルダに振り分けられている可能性がございますので、
            <br />
            お手数ですが、ご確認いただき再度ご連絡をお願いします。
          </p>
        </div>
      </CompleteLayout>
    </Layout>
  );
};

export default CompletePage;
